import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';

const Donate = ({ data }) => {
  const { donate } = data;

  return (
    <Layout bgcolor={donate.backgroundColor} wrapperClass="donate" accentColor={donate.accentColor}>
      <HelmetDatoCms seo={donate.seoMetaTags} />
      <div className="donate-intro container row">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <h1 className="gothic--large large-title">{donate.title}</h1>
          {donate.featuredImage && (
            <Img fluid={{ ...donate.featuredImage.fluid }} alt={donate.featuredImage.alt} className="donate-image" />
          )}
          {donate.featuredImage.title && (
            <p
              className="caption"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: donate.featuredImage.title,
              }}
            />
          )}
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: donate.description,
            }}
          />
        </div>
      </div>
      <div className="divider container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={donate.accentColor} />
        </div>
      </div>
    </Layout>
  );
};

Donate.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default Donate;

export const query = graphql`
  query donateQuery {
    donate: datoCmsDonate {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      backgroundColor
      accentColor {
        hex
      }
      title
      featuredImage {
        alt
        fluid(maxHeight: 500) {
          ...GatsbyDatoCmsSizes_noBase64
        }
        title
      }
      description
    }
  }
`;
